@import "assets/shared-scss/lib.scss";
.cp-burger-menu {
  width: 100%;
  position: sticky;
  top: 0;
  overflow: hidden;
  z-index: 9999;

  .cp-burger-menu-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;

    img {
      width: 138px;
    }
  }
  .cp-burger-menu-btn {
    min-width: 0;
    span {
      margin: 0;
    }
  }
}
.cp-burger-menu-drawer {
  .header-search {
    padding-top: 100px;
  }
  .sidebar-list,
  .sidebar-options {
    min-width: 375px;

    a {
      color: #0e120f;
    }

    img {
      max-width: 40px;
      margin-right: 8px;
      margin-left: -12px;
    }

    .sidebar-list-item-button {
      display: flex;
      align-items: center;
      padding: 10px 23px;

      &.Mui-selected,
      &:hover {
        background-color: #dffdf1;
        .sidebar-list-item-icon {
          svg {
            path {
              stroke: $green-color;
            }
          }
        }
      }

      .sidebar-list-item-text {
        margin-top: 6px;
      }

      .sidebar-list-item-icon {
        min-width: 38px;
        svg {
          path {
            stroke: #98a2b3;
          }
        }
      }
    }

    .sidebar-list-item-button-2 {
      display: flex;
      align-items: center;
      padding: 10px 23px;

      .sidebar-list-item-text {
        margin-top: 6px;
      }
    }
    .btn-sample-register {
      width: 100%;
    }
  }
  .sidebar-options {
    padding-top: 10px;
  }

  .header-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .MuiFormControl-root.input-header-search {
      width: 330px;
      margin: 0px auto 20px auto;

      .MuiOutlinedInput-root {
        width: 330px;
        height: 48px;
      }

      .MuiInputBase-input {
        //   padding: 8px 10px 10px 45px !important;
        z-index: 1;

        font-weight: 400;
        &::placeholder {
          color: #abadab;
          @include inter-font;
          @include font-size(16);
          opacity: 1; /* Firefox */
        }
      }

      fieldset {
        //   height: 36px;
        border-radius: 6px;
        border-color: #abadab !important;
        background: #fff;
        z-index: 0;
      }

      .Mui-focused {
        background: transparent;
        //   width: 205px;
        &.MuiInputLabel-formControl {
          svg {
            path {
              stroke: $green-color;
            }
          }
        }

        fieldset {
          background: #dffdf1;
          border-color: $green-color !important;
        }
        svg {
          path {
            stroke: $green-color;
          }
        }
      }

      .cp-search-input-icon {
        z-index: 2;
        background-color: transparent;
        cursor: pointer;
      }
      svg {
        height: 20px;
        width: 20px;
        path {
          stroke: #383c45;
        }
      }
    }
  }
}
