@import "assets/shared-scss/lib.scss";

.panel-container {
  padding: 35px;
  display: flex;
  min-height: 100vh;

  @include for-laptop {
    flex-wrap: wrap;
  }

  .sidebar {
    @include inter-font;
    flex: 100%;
    max-width: 278px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #c9ced7;
    background: #0e120f;
    box-shadow: 0px 1px 13px rgba(0, 20, 56, 0.1);
    border-radius: 12px;
    padding: 0 10px;
    position: sticky;
    top: 35px;

    .sidebar-list {
      flex: 1;
      a {
        color: unset;
      }
    }
    .sidebar-logo {
      max-width: 230px;
      width: 100%;
      height: 56px;
      margin: 27px 0;
      padding: 0 10px;
      object-fit: contain;
    }

    .sidebar-list-item-icon svg path {
      stroke: #c9ced7;
    }

    .sidebar-list-item {
      margin-bottom: 16px;

      .sidebar-list-item-icon {
        color: #c9ced7;
        min-width: 40px;
      }
      .sidebar-list-notification {
        @include inter-font;
        @include font-size(14);
        color: #fff;
        aspect-ratio: 1/1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $green-color;
        border-radius: 55px;
        padding: 4px;

        min-width: 26px;
        min-height: 26px;
        margin-right: 0px;
      }

      .sidebar-list-item-button {
        border-radius: 8px;
        transition: 300ms;

        &:hover,
        &.Mui-selected {
          background: rgba($green-color, 0.4);

          .sidebar-list-item-icon svg path {
            stroke: $green-color;
          }
        }
      }
    }
  }
  .sidebar-options {
    flex: 100%;
    align-self: flex-end;
    padding-bottom: 17px;
    padding-top: 30%;

    .cp-user-list-item {
      margin-bottom: 10px;
      color: unset;

      p {
        @include inter-font;
        @include font-size(20);
        @include line-height(24);
        font-weight: 600;
        word-wrap: break-word;
        max-width: 165px;
      }

      .cp-user-image {
        max-width: 48px;
        width: 48px;
        max-height: 48px;
        object-fit: cover;
        border-radius: 100%;
        height: 48px;
        display: block;
        object-position: left;
        margin-right: 14px;
      }
    }
  }

  .cp-content-container {
    padding-top: 10px;
    margin-left: 36px;
    width: 100%;
    max-width: calc(100% - 310px);
    @include for-laptop {
      max-width: 100%;
      margin-left: 0;
    }
  }
  .cp-pagelayout-loading {
    width: 70px;
    height: 70px;
    margin: auto;
  }
}
