@import "assets/shared-scss/lib.scss";

.MuiFormControl-root.cp-search-input {
  p.MuiFormHelperText-root {
    height: 0px;
    position: absolute;
    bottom: -4px;
  }

  .MuiInputLabel-formControl {
    @include inter-font;
    @include font-size(14);
    @include line-height(20);
    color: #0e120f;
    font-weight: 500;
    position: relative;
    top: -20px;
    left: -14px;
  }

  .MuiInputBase-input {
    @include font-size(14);
    padding: 8.5px 14px 8.5px 0;
    z-index: 1;
  }
  fieldset {
    border-radius: 8px;
    border-color: #c9ced7;
    background: #ffffff;
    z-index: 0;
  }
  .cp-search-input-icon {
    z-index: 2;
  }

  .Mui-focused {
    fieldset {
      border-color: $green-color;
    }
    svg {
      path {
        stroke: $green-color;
      }
    }
  }

  svg {
    width: 14px;
    height: 14px;
    path {
      stroke: #383c45;
    }
  }
}
